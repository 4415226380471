import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import { z } from "zod"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function removeCurlies(input: string): string {
  return input.replace(/\[(.*?)\]/g, "")
}

export function timeAgo(date: Date) {
  const seconds = Math.floor((+new Date() - +date) / 1000)

  let interval = Math.floor(seconds / 31536000)
  if (interval >= 1) {
    return interval === 1 ? `${interval} year ago` : `${interval} years ago`
  }

  interval = Math.floor(seconds / 2592000)
  if (interval >= 1) {
    return interval === 1 ? `${interval} month ago` : `${interval} months ago`
  }

  interval = Math.floor(seconds / 86400)
  if (interval >= 1) {
    return interval === 1 ? `${interval} day ago` : `${interval} days ago`
  }

  interval = Math.floor(seconds / 3600)
  if (interval >= 1) {
    return interval === 1 ? `${interval} hour ago` : `${interval} hours ago`
  }

  interval = Math.floor(seconds / 60)
  if (interval >= 1) {
    return interval === 1 ? `${interval} minute ago` : `${interval} minutes ago`
  }

  return seconds < 10 ? "just now" : `${Math.floor(seconds)} seconds ago`
}

export function getFirstZodError(
  errors: z.ZodError | null,
  key: string
): string | undefined {
  if (errors) {
    return errors.flatten().fieldErrors[key]?.[0] || ""
  }
}

export function blurImage(src: string, value: number): string {
  return `https://resize.fanmade.ai/?blur=${value}&image=${src}`
}

export function rsize(src: string, w: number): string {
  return `https://resize.fanmade.ai/?width=${w}&fit=contain&image=${src}`
}

export function srcset(src: string): string {
  const sizes: number[] = [400, 600, 800, 1000, 1200, 1400, 1600]
  return sizes.map((size) => `${rsize(src, size)} ${size}w`).join(", ")
}
